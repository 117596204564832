import React from "react";
import Loader from "../components/Loader";
import locale from "../i18n/locale";
import { BarChart, PieChart, pieArcLabelClasses } from "@mui/x-charts";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import KaryotypeBar from "../components/KaryotypeBar";
import chrx from "../karyotype/GRCh38.json";

function Pie({ title, ctg, data, limit = 5 }) {
  if (!data) return "";

  const Tableau10 = [
    "#4e79a7",
    "#f28e2c",
    "#e15759",
    "#76b7b2",
    "#59a14f",
    "#edc949",
    "#af7aa1",
    "#ff9da7",
    "#9c755f",
    "#bab0ab",
  ];

  let add = (ps, a) => ps + a;

  let total = data.map((v) => v.value).reduce(add, 0);

  if (data.length > limit) {
    let other = data
      .slice(5)
      .map((v) => v.value)
      .reduce(add, 0);
    data = [
      ...data.slice(0, limit),
      { label: "Other", value: other, percent: (100 * other) / total },
    ];
  }
  //console.log(pieArcLabelClasses);

  return (
    <Paper className="pie" elevation={2}>
      <h4 className="pie-title">
        {title} ({total}). {ctg}:
      </h4>
      <PieChart
        colors={Tableau10}
        series={[
          {
            data: data.map((v) => ({
              label: `${v.label} (${parseInt(v.percent)}%)`,
              value: v.value,
            })),
            cx: 90,
            cy: 80, // + 100,
            highlightScope: { faded: "global", highlighted: "item" },
            faded: { color: "gray" },
            innerRadius: 16,
            outerRadius: 80,
            paddingAngle: 3,
            cornerRadius: 3,
          },
        ]}
        slotProps={{
          legend: {
            direction: "row",
            position: {
              horizontal: "left",
              vertical: "bottom",
            },
          },
        }}
        height={320}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: "white",
            fontSize: 10,
          },
        }}
      />
    </Paper>
  );
}

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.loader = new Loader(this);
    this.state = { gene: [], search: "", variants: {}, chr: "1" };
    this.karyotype = React.createRef();
    this.timer = null;
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  chrRender() {
    let w_max = chrx["1"][chrx["1"].length - 1][1];
    Object.keys(chrx).map((k) => {
      if (!this.state.variants[k]) return;
      let w = this.state.width - (30 + 12) * 2;
      let width = (chrx[k][chrx[k].length - 1][1] * w) / w_max;
      //width = chrx[k][chrx[k].length - 1][1];
      KaryotypeBar(`#chr-map-${k}`, chrx[k], width, this.state.variants[k]);
    });
  }

  handleWindowResize() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ width: window.innerWidth }, () => {
        this.chrRender();
      });
      console.log("window.innerWidth", window.innerWidth);
    }, 250);
  }

  componentDidMount() {
    this.setState({ width: window.innerWidth });
    this.loader.get("overview", {}, (url, data) => {
      this.setState(data, () => {
        this.chrRender();
      });
    });
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  search() {
    if (this.state.search.length === 0) return;
    location.href = "/variant?s=" + this.state.search;
  }

  render() {
    return (
      <>
        <div className="header-index">
          <h2>The PLoV database</h2>
          <p>
            The PLoV database contains a comprehensive list of the variants
            found in families who have experienced single or recurrent pregnancy
            loss, as well as supporting information about all cases retrieved
            from the original studies.
          </p>
          <div className="quick-search">
            <Paper className="v-search" component="form">
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Quick variants search"
                onChange={(e) => {
                  this.setState({ search: e.target.value });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    this.search();
                  }
                }}
              />
              <IconButton
                onClick={() => {
                  this.search();
                }}
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
        </div>
        <div className="index-content">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Pie
                title="Variants"
                ctg="Most frequent genes"
                data={this.state.gene}
                limit={7}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Pie
                title="Cases"
                ctg="Outcomes"
                data={this.state.outcome}
                limit={4}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Pie
                title="Cases"
                ctg="Recurrence"
                data={this.state.recurrence}
                limit={7}
              />
            </Grid>
          </Grid>
          <div ref={this.karyotype}></div>
        </div>
        <div className="chr-select">
          <FormControl fullWidth>
            <InputLabel>Chromosome</InputLabel>
            <Select
              size="small"
              value={this.state.chr}
              label="Chromosome"
              onChange={(e) => {
                this.setState({ chr: e.target.value }, () => {
                  this.chrRender();
                });
              }}
            >
              {Object.keys(chrx).map((k) => (
                <MenuItem value={k} key={k}>
                  {k}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="chrx">
          {Object.keys(chrx).map((k) => {
            if (k != this.state.chr) return "";
            if (!this.state.variants[k]) return;
            return (
              <Paper key={k} className="chr-item" elevation={2}>
                <div className="chr-title">
                  Chromosome: {k}{" "}
                  <span>Variants: {this.state.variants[k].length}</span>
                </div>
                <div className="chr-karyo" id={`chr-map-${k}`}>
                  {k}
                </div>
              </Paper>
            );
          })}
        </div>
      </>
    );
  }
}

export default IndexPage;
