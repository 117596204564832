import React from "react";

class AboutPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div className="header-index">
          <h2>About</h2>
          <p>
            PLoV is a database consisting of four main tables: Variants, Cases,
            Studies and Observations. Each of these tables provides detailed
            descriptive information about the variant and the settings and
            cases/families in which it was found. The main page provides
            information about nucleotide substitutions and the amino acid
            substitutions they lead to, as well as the gene and chromosome in
            which this SNP is located. Each variant has its own Variant ID which
            allows the user to link the detected nucleotide substitution with
            the case (family) in which it was found. In the Cases table, we
            tried to provide all significant information about the fetus,
            parents, observed phenotypes and pregnancy duration and results
            which were presented in the articles. Each case has its own Case ID
            which is associated with the Observations table (as well as Variant
            ID), which allows researchers to track the genotype of the parents
            and fetus. From the Cases section with the help of Study ID, users
            can get information about specific study in which this case was
            described. The Study table contains data about the cohort and method
            used in the article, as well as related information that allows to
            find the article.
          </p>

          <h3>Assessment of Confidence Levels for Genetic Variants</h3>
          <p>
            <i>Low Confidence:</i> Variants are assigned a low confidence level
            if they lack strong indicators of pathogenicity or sufficient
            supporting data. Specifically, this level is designated when:
          </p>
          <ul>
            <li>
              At least once variant is classified as benign (B) or likely benign
              (LB).
            </li>
            <li>
              The variant is classified as a variant of uncertain significance
              (VUS) with no available fetal genotype data.
            </li>
            <li>
              The variant is classified as VUS, and parental genotype data are
              available, but no corresponding phenotype or gestational age
              information is provided.
            </li>
          </ul>
          <p>
            <i>Intermediate Confidence:</i> The intermediate confidence level is
            assigned to variants with more supporting evidence than low
            confidence but still lacking complete data for a high-confidence
            designation. Criteria include:
          </p>

          <ul>
            <li>
              Variants classified as VUS with fetal genotype information
              available.
            </li>
            <li>
              VUS classifications with both parental genotype and phenotype
              information, with reported gestational age.
            </li>
            <li>
              Variants classified as pathogenic (P) or likely pathogenic (LP)
              without fetal genotype data and no available parental genotype.
            </li>
            <li>
              At least one P or LP classification, parental genotype data
              available, but no phenotype information (reported gestational age
              is highly relevant in such cases).
            </li>
          </ul>

          <p>
            <i>High Confidence:</i> This level is reserved for findings with the
            strongest supporting evidence. A variant is considered high
            confidence if:
          </p>

          <ul>
            <li>
              At least once variant is classified as P or LP, with corresponding
              fetal genotype data available.
            </li>
            <li>
              At least one P or LP variant is identified with parental genotype
              and a gestational age indicated in the phenotype.
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default AboutPage;
